import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { STORAGE_KEY } from "@common/index";
import Storage from "@utils/storage";
import { loginThunk, getUserProfileThunk } from "./thunk";
import { IUser } from "src/types";
interface AuthState {
  isAuthenticated?: boolean;
  error?: string | null | any;
  loading: boolean;
  userProfile?: IUser;
}
const storedAuthToken = Storage().get(STORAGE_KEY.ACCESS_TOKEN);

const initialState: AuthState = {
  error: null,
  loading: false,
  isAuthenticated: !!storedAuthToken,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutAction: (state) => {
      state.error = null;
      state.isAuthenticated = false;
      Storage().remove(STORAGE_KEY.W3A_TOKEN);
      Storage().remove(STORAGE_KEY.ACCESS_TOKEN);
      Storage().remove(STORAGE_KEY.REFRESH_TOKEN);
    },
    setUserAuth: (state, action: PayloadAction<any>) => {
      state.isAuthenticated = true;
      state.userProfile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      loginThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.isAuthenticated = true;
        Storage().set(STORAGE_KEY.ACCESS_TOKEN, action.payload?.api_key);
        Storage().set(STORAGE_KEY.REFRESH_TOKEN, action.payload?.refresh_token);
      },
    );
    builder.addCase(loginThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getUserProfileThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getUserProfileThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.userProfile = action.payload;
      },
    );
    builder.addCase(getUserProfileThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.isAuthenticated = false;
      state.userProfile = undefined;
    });
  },
});

export const { logoutAction } = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export default authSlice.reducer;
