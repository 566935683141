import { useAppDispatch, useAppSelector } from "@hooks/store";
import { useLanguage } from "@hooks/useLanguage";
import { logoutAction } from "@redux/slice/auth";
import { loginThunk } from "@redux/slice/auth/thunk";
import {
  OPENLOGIN_NETWORK_TYPE,
  WALLET_ADAPTERS,
  // WEB3AUTH_NETWORK,
} from "@web3auth/base";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ICustomConfigs, chain } from "./../configs/chainConfig";
import { IWalletProvider, getWalletProvider } from "./walletProvider";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import Storage from "@utils/storage";
export interface IWeb3AuthContext {
  web3Auth: Web3AuthNoModal | null;
  connected: boolean;
  provider: IWalletProvider | null;
  isLoading: boolean;
  user: any;
  address: string | null;
  balance: string | null;
  chainId?: string | null;
  playgroundConsole: string;
  publicKey: string;
  connectedChain?: ICustomConfigs;
  loginGG: () => Promise<void>;
  loginAP: () => Promise<void>;
  logout: () => Promise<void>;
  getUserInfo: () => Promise<any>;
  getAddress: () => Promise<string | null | undefined>;
  getBalance: () => Promise<string | null | undefined>;
  getSignature: (message: string) => Promise<string | null | undefined>;
  getPrivateKey: () => Promise<string | null | undefined>;
  getChainId: () => Promise<string | "" | undefined>;
}

export const Web3AuthContext = createContext<IWeb3AuthContext>({
  web3Auth: null,
  provider: null,
  isLoading: false,
  connected: false,
  user: null,
  address: null,
  balance: null,
  chainId: null,
  playgroundConsole: "",
  publicKey: "",
  connectedChain: chain["Polygon"],
  loginGG: async () => {},
  loginAP: async () => {},
  logout: async () => {},
  getUserInfo: async () => null,
  getAddress: async () => "",
  getBalance: async () => "",
  getSignature: async () => "",
  getPrivateKey: async () => "",
  getChainId: async () => "",
});

export function useWeb3Auth(): IWeb3AuthContext {
  return useContext(Web3AuthContext);
}

interface IWeb3AuthProps {
  children?: ReactNode;
  setLocale?: string;
}

const clientId = process.env.REACT_APP_WEB3_CLIENT_ID || "";

const privateKeyProvider = new EthereumPrivateKeyProvider({
  config: { chainConfig: chain["Polygon"] },
});


async function initializeWeb3Auth(language: string, pathname: string) {
  const web3AuthInstance = new Web3AuthNoModal({
    clientId,
    web3AuthNetwork: process.env
      .REACT_APP_WEB3_NETWORK as OPENLOGIN_NETWORK_TYPE,
    privateKeyProvider,
    uiConfig: {
      mode: "dark",
      defaultLanguage: language as any,
    },
  });
  const openloginAdapter = new OpenloginAdapter({
    adapterSettings: {
      uxMode: "redirect",
      redirectUrl: `${window.location.origin}${pathname ? pathname : "/nftsale2024alice"}`,
      whiteLabel: {
        appName: "sleepass",
        defaultLanguage: (language as any) ?? "en",
        mode: "dark",
      },
    },
  });
  web3AuthInstance.configureAdapter(openloginAdapter);
  await web3AuthInstance.init();
  return web3AuthInstance;
}

export const Web3AuthProvider = ({ children }: IWeb3AuthProps) => {
  const [web3Auth, setWeb3Auth] = useState<Web3AuthNoModal | null>(null);
  const [provider, setProvider] = useState<IWalletProvider | null>(null);
  const [address, setAddress] = useState<string | null>(null);
  const [balance, setBalance] = useState<string | null>(null);
  const [user, setUser] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [playgroundConsole, setPlaygroundConsole] = useState<string>("");
  const [connected, setConnected] = useState<boolean>(false);
  const [publicKey, setPublicKey] = useState<string>("");
  const { language } = useLanguage();
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const uiConsole = useCallback(
    (...args: unknown[]) => {
      setPlaygroundConsole(
        `${JSON.stringify(args || {}, null, 2)}\n\n\n\n${playgroundConsole}`,
      );
    },
    [playgroundConsole],
  );
  const location = useLocation();
  const checkExpiredTimeToken = (token: string) => {
    const { exp }: { exp: number } = jwtDecode(token);
    if (dayjs().isAfter(dayjs(Number(exp) * 1000))) {
      logout();
    }
  };
  useEffect(() => {
    async function init() {
      try {
        setIsLoading(true);
        const web3AuthInstance = await initializeWeb3Auth(language, location.pathname);
        const walletProvider = getWalletProvider(
          web3AuthInstance.provider,
          uiConsole,
        );
        setAddress(await walletProvider.getAddress());
        setProvider(walletProvider);
        if (web3AuthInstance.status === "connected") {
          setUser(await web3AuthInstance.getUserInfo());
          setConnected(true);
          setPublicKey(
            await getPublicKey(await web3AuthInstance.getUserInfo()),
          );
        }
        setWeb3Auth(web3AuthInstance);
      } catch (error) {
        // uiConsole(error);
      } finally {
        setIsLoading(false);
      }
    }
    init();
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      (async () => {
        if (
          auth.isAuthenticated === false &&
          web3Auth?.status === "connected" &&
          provider
        ) {
          dispatch(
            loginThunk({
              public_key: await getPublicKey(user),
              token: user?.idToken,
              walletAddress: address as string,
            }),
          );
        }
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3Auth, address, user],
  );

  useEffect(() => {
    if (user?.idToken) checkExpiredTimeToken(user?.idToken);
    // eslint-disable-next-line
  }, [user, location]);
  const loginGG = async () => {
    try {
      setIsLoading(true);
      if (!web3Auth) {
        return;
      }

      const web3authProvider = await web3Auth.connectTo(
        WALLET_ADAPTERS.OPENLOGIN,
        {
          loginProvider: "google",
          mfaLevel: 'none'
        },
      );

      const walletProvider = getWalletProvider(web3authProvider, uiConsole);
      setProvider(walletProvider);

      // if (web3Auth.connected) {
      //   setUser(await web3Auth.getUserInfo());
      //   setConnected(true);
      //   setIsLoading(false)
      // }
    } catch (error: any) {
      // if (error?.code === "5003") {
      //   setIsLoading(true)
      // } else {
      //   setIsLoading(false)
      // }
    } finally {
      Storage("session").set("PREVIOUS_PAGE", "Login");
      setIsLoading(false);
    }
  };

  const loginAP = async () => {
    try {
      setIsLoading(true);
      if (!web3Auth) {
        return;
      }

      const web3authProvider = await web3Auth.connectTo(
        WALLET_ADAPTERS.OPENLOGIN,
        {
          loginProvider: "apple",
          mfaLevel: 'none'
        },
      );

      const walletProvider = getWalletProvider(web3authProvider, uiConsole);
      setProvider(walletProvider);

      // if (web3Auth.status === "connected") {
      //   const userInfo = await getUserInfo()
      //   const public_key = await getPublicKey(userInfo)
      //   setUser(userInfo);
      //   dispatch(loginThunk(public_key))
      //   setConnected(true);
      //   setIsLoading(false)
      // }
    } catch (error: any) {
      // if (error?.code === "5003") {
      //   setIsLoading(true)
      // } else {
      //   setIsLoading(false)
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    try {
      if (!web3Auth) {
        return;
      }
      await web3Auth.logout();
      setProvider(null);
      setConnected(false);
      dispatch(logoutAction());
    } catch (error) {}
  };

  const getUserInfo = async () => {
    try {
      if (!web3Auth) {
        return;
      }
      const userInfo = await web3Auth.getUserInfo();
      setUser(userInfo);
      uiConsole(userInfo);
      return userInfo;
    } catch (error) {}
  };

  const getPublicKey = async (user: any) => {
    try {
      const token = user?.idToken;
      if (!token) {
        setPublicKey("");
        return;
      }

      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(""),
      );
      return JSON.parse(jsonPayload).wallets[0].public_key;
    } catch (error) {}
  };

  const getAddress = async () => {
    try {
      if (!provider) {
        return "";
      }

      const updatedAddress = await provider.getAddress();
      setAddress(updatedAddress);
      uiConsole(updatedAddress);
      return address;
    } catch (error) {}
  };

  const getBalance = async () => {
    try {
      if (!web3Auth) {
        return "";
      }

      if (!provider) {
        return "";
      }

      const updatedBalance = await provider.getBalance();

      setBalance(updatedBalance);
      uiConsole(updatedBalance);
      return balance;
    } catch (error) {}
  };

  const getSignature = async (message: string) => {
    try {
      if (!web3Auth) {
        return "";
      }
      if (!provider) {
        return "";
      }
      const signature = await provider.getSignature(message);
      uiConsole(signature);
      return signature;
    } catch (error) {}
  };

  const getPrivateKey = async () => {
    try {
      if (!web3Auth) {
        return "";
      }
      if (!provider) {
        return "";
      }
      const privateKey = await provider.getPrivateKey();
      uiConsole("Private Key: ", privateKey);
      return privateKey;
    } catch (error) {}
  };

  const getChainId = async () => {
    try {
      if (!web3Auth) {
        uiConsole("web3auth not initialized yet");
        return "";
      }
      if (!provider) {
        return "";
      }
      await provider.getChainId();
    } catch (error) {}
  };

  const contextProvider = {
    web3Auth,
    provider,
    user,
    isLoading,
    address,
    balance,
    playgroundConsole,
    connected,
    publicKey,
    loginGG,
    loginAP,
    logout,
    getUserInfo,
    getAddress,
    getBalance,
    getSignature,
    getPrivateKey,
    getChainId,
  };
  return (
    <Web3AuthContext.Provider value={contextProvider}>
      {children}
    </Web3AuthContext.Provider>
  );
};
