import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { EN } from "@trans/en";
import { JA } from "@trans/ja";
import Storage from '@utils/storage'
import { STORAGE_KEY } from "@common/index";
const DEFAULT_LANGUAGE = "ja";
const language = Storage().get(STORAGE_KEY.LANGUAGE) || DEFAULT_LANGUAGE;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: EN,
      },
      ja: {
        translation: JA,
      },
    },
    // lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: DEFAULT_LANGUAGE,

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },

    lng: language,
  });
i18n.on("languageChanged", (ln) => {
  Storage().set(STORAGE_KEY.LANGUAGE, ln)
});
export default i18n;
