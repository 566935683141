import { IEventGroup } from "@dto/index";
import { RootState } from "@redux/store";
import { createSlice } from "@reduxjs/toolkit";
import Storage from "@utils/storage";

interface TicketState {
  error?: string | null | any;
  loading: boolean;
  event_group?: IEventGroup;
}

const initialState: TicketState = {
  error: null,
  loading: false,
  event_group: Storage("session").get("EVENT_GROUP"),
};

export const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    setGroupEventId: (state, action) => {
      state.event_group = action.payload;
    },
  },
});
export const { setGroupEventId } = ticketsSlice.actions;

export const selectTicket = (state: RootState) => state.ticket;

export default ticketsSlice.reducer;
