import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Web3AuthProvider } from "./contexts/Web3Context";
import { useAppDispatch, useAppSelector } from "@hooks/store";
import { getUserProfileThunk } from "@redux/slice/auth/thunk";
import LazyLoading from "@utils/load";
const Layouts = LazyLoading(() => import ("./layouts"));
const NewsPage = LazyLoading(() => import ("@pages/News"));
const HomePage = LazyLoading(() => import ("@pages/Home"));
const NewDetail = LazyLoading(() => import ("@pages/News/NewDetail"));
const Terms = LazyLoading(() => import ("@pages/Terms"));
const Privacy = LazyLoading(() => import ("@pages/Privacy"));
const Disclaimer = LazyLoading(() => import ("@pages/Disclaimer"));
const TopNFTPage = LazyLoading(() => import ("@pages/Top"));
const Web3Login = LazyLoading(() => import ("@pages/Web3Auth"));
const GatchaPage = LazyLoading(() => import ("@pages/Top/Gatcha"));
const GatchaLottie = LazyLoading(() => import ("@pages/Top/GatchaLottie"));
const GatchaDetail = LazyLoading(() => import ("@pages/Top/GatchaDetail"));
const GatchaCompleted = LazyLoading(() => import ("@pages/Top/GatchaCompleted"));

const App = () => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated) dispatch(getUserProfileThunk({}));
  }, [dispatch, isAuthenticated]);
  return (
    <BrowserRouter>
      <Web3AuthProvider>
        <Routes>
          <Route path={""} element={<Layouts />}>
            <Route index={true} element={<HomePage />} />
            <Route path={"home"} element={<HomePage />} />
          </Route>
          <Route path={"/news"} element={<Layouts />}>
            <Route index={true} element={<NewsPage />} />
            <Route path={":id"} element={<NewDetail />} />
          </Route>
          <Route path={"/terms"} element={<Layouts />}>
            <Route index={true} element={<Terms />} />
          </Route>
          <Route path={"/privacy"} element={<Layouts />}>
            <Route index={true} element={<Privacy />} />
          </Route>
          <Route path={"/disclaimer"} element={<Layouts />}>
            <Route index={true} element={<Disclaimer />} />
          </Route>
          <Route path={"/payment-redirect"} element={<Layouts isAuth />}>
            <Route index={true} element={<GatchaPage />} />
          </Route>
          <Route path={"/nftsale2024alice"} element={<Layouts isTopPage isAuth />}>
            <Route index={true} element={<TopNFTPage />} />
          </Route>
          <Route path={"/nftsale2024papiko"} element={<Layouts isTopPage isAuth />}>
            <Route index={true} element={<TopNFTPage />} />
          </Route>
          <Route path="/login" element={<Layouts />}>
            <Route index={true} element={<Web3Login />} />
          </Route>
          {/* <Route path="/tickets" element={<Layouts isAuth />}>
            <Route index={true} element={<GatchaPage />} />
          </Route> */}
          <Route path="/gatcha/end" element={<Layouts isAuth />}>
            <Route index={true} element={<GatchaDetail />} />
          </Route>
          <Route path="/gatcha/open" element={<Layouts isAuth />}>
            <Route index={true} element={<GatchaCompleted />} />
          </Route>
          <Route path="/gatcha/lottie">
            <Route index={true} element={<GatchaLottie />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Web3AuthProvider>
    </BrowserRouter>
  );
};

export default App;
