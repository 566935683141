import axios, { AxiosResponse, CreateAxiosDefaults, InternalAxiosRequestConfig } from 'axios'
import Storage from '@utils/storage'
import { STORAGE_KEY } from '@common/index'

// Set config defaults when creating the instance
const TIMEOUT_REQUEST = 300000

const configs: (
  token?: string,
  type?: 'main' | 'external',
) => CreateAxiosDefaults<any> = (token, type) => ({
  baseURL: type === 'main' ? `${process.env.REACT_APP_API_URL}/v1` : '',
  headers: {
    Accept: type === 'external' ? `*` : 'application/json',
    Authorization: token && type === 'main' ? `Bearer ${token}` : undefined,
    'Content-type': 'application/json',
  },
  timeout: type === 'main' ? TIMEOUT_REQUEST : undefined,
})

class AxiosInstance {
  static getInstance = (type: 'main' | 'external' = 'main', auth = false) => {
    const token = auth ? Storage().get(STORAGE_KEY.ACCESS_TOKEN) : undefined
    const axiosClient = axios.create(configs(token, type))
    if (type === 'main') {
      axiosClient.interceptors.request.use(
        function (config: InternalAxiosRequestConfig<any>) {
          return config
        },
        (err: any) => {
          return Promise.reject(err)
        }
      )
      axiosClient.interceptors.response.use(
        function (res: AxiosResponse<any, any>) {
          return res.data
        },
        (err: any) => {
          if ([401, 403].includes(err.response?.status)) {
            window.location.replace('/')
          }
          return Promise.reject(err)
        }
      )
    }
    return axiosClient
  }
  static get(url: string, params?: any): Promise<AxiosResponse<any, any>> {
    return AxiosInstance.getInstance().request({
      method: 'GET',
      url,
      params
    })
  }

  static post(url: string, data?: any, params?: any): Promise<AxiosResponse<any, any>> {
    return AxiosInstance.getInstance().request({
      method: 'POST',
      url,
      data,
      params
    })
  }

  static put(url: string, params: any, data: any): Promise<AxiosResponse<any, any>> {
    return AxiosInstance.getInstance().request({
      method: 'PUT',
      url,
      params,
      data
    })
  }

  static delete(url: string, params?: any): Promise<AxiosResponse<any, any>> {
    return AxiosInstance.getInstance().request({
      method: 'DELETE',
      url,
      params
    })
  }
}

export default AxiosInstance;