import { descriptors } from "chart.js/dist/core/core.defaults";

export const JA: {
  [key: string]: any;
} = {
  "No data": "データがありません",
  "SLEEPASS is a sleep management app equipped with game-fi features":
    "SLEEPASSは、game-fi 機能を搭載した睡眠管理アプリです。",
  "By nurturing and hatching EGG (NFT) in the gaming world, you can earn SHEEPN (NFT).":
    "あなたはゲーム世界でEGG（NFT）を育てて孵化させることでSHEEPN（NFT）を獲得します。",
  "In the real world, obtaining high-quality sleep allows you to accquire even rarer NFTs.":
    "現実世界で良質な睡眠をとることでよりレアリティの高いNFTを獲得することが可能です。",
  "Furthermore, by using these NFTs to participate in contests and achieve a high ranking, you can also earn Governance Tokens (GSPT).":
    "さらに、それらのNFTを用いてコンテストに参加し、ランキングで上位に入るとガバナンストークン（GSPT）の獲得も可能です。",
  "Enjoy a new experience of acquiring NFTs while improving your sleep in a gamified manner.":
    "睡眠を改善しながらゲーム感覚でNFTを得られる新しい体験を楽しみましょう。",
  "Please download the SLEEPASS app from the App Store (iOS) or Play Store (Android).":
    "SLEEPASSアプリをダウンロードする App Store（iOS）または Play Store（Android）からダウンロードしてください。",
  "※ Comming soon": "※ 近日公開予定",
  "Launch SLEEPASS and set an EGG in the SLOT. When accessing the app for the first time on each device, you can obtain an EGG (basic version for free). Afterward, feel free to set the EGG you want to hatch in the SLOT.":
    "SLEEPASSを起動してSLOTにEGGを設定する 各端末で初めてアプリにアクセスすると、EGGを獲得できます（基本無料）。 その後、SLOTに孵化させたいEGGをお好みで設定してください。",
  "Set the alarm Set an alarm for you planned wake-up time. Set it for a time that allows you to get enough sleep.":
    "アラームの設定をする 起床予定の時間にアラームを設定しましょう。 あなたにとって十分な睡眠が取れる時間に設定しましょう。",
  "We plan to organize a CONTEST where you can assemble a deck with SHEEPN and compete in rankings against other users!":
    "SHEEPNでデッキを編成し、他ユーザーとのランキングを競うCONTESTを開催予定！",
  "Start collecting SHEEPN from now to boost your SLEEP RANK in preparation for the CONTEST!":
    "CONTESTに備えて今からSHEEPNを集めてSLEEP RANKを上げておこう！",
  "We detect falling asleep/waking up using smartphone sensors and score sleep results based on our unique sleep evaluation logic.":
    "入眠／起床をスマートフォンのセンサーを用いて検知し、独自の睡眠評価ロジックを元に睡眠結果をスコアリングする。",
  "In addition to Level 1, we import sleep data from health-related devices equipped with sleep detection features, such as smartwatches like the Apple Watch, and other healthcare-related devices, via HealthKit/Google Fit to the app, providing more accurate sleep data input.":
    "Level.1 に加え、Apple Watch に代表されるスマートウォッチや、その他、睡眠検知機能を備えたヘルスケア関連デバイスから睡眠データをHealthKit／Google Fit 経由で、App にインポートし、より精度の高い睡眠データを入力する。",
  "Feedback to EGG/SHEEPN": "EGG/SHEEPN へフィードバック",
  Contest: "コンテスト",
  "・Assemble a deck with 3 SHEEPN and compete based on the total Contest Points":
    "・3体のSHEEPNでデッキを編成し、合計コンテストポイントの高さを競う",
  "Determination of Contest Points": "コンテストポイントの決定",
  "・Total parameter values serve as the base": "・パラメータの合計がベース",
  "・For each contest, there are specific theme traits and parameters. If there's a match, a bonus is added (Theme Bonus, Parameter Bonus)":
    "・コンテストごとに、テーマとなる素質タイプとパラメータがあり、一致している場合はボーナスが加算される（テーマボーナス、パラメータボーナス）",
  "Recommended Trait Bonus": "推奨素質ボーナス",
  "・When the trait type matches the contest's theme, n% of the entire SHEEPN parameter values are added as a bonus":
    "・素質タイプがコンテストのテーマと一致している場合、SHEEPNのパラメータ全体のn%がボーナスとして加算される",
  "Recommended Parameter Bonus": "推奨パラメータボーナス",
  "・For the parameters set as the theme of the contest, m% of the corresponding SHEEPN parameter values are added as a bonus":
    "・コンテストのテーマとして設定されているパラメータについて、SHEEPNの該当のパラメータのm%がボーナスとして加算される",
  "Support Bonus": "応援ボーナス",
  "Depending on the number of ★ rarities of SHEEPN with trait types that match the contest's theme and are not set in the deck, a bonus is added":
    "デッキに設定していないSHEEPNのうち、コンテストのテーマと一致する素質タイプのSHEEPNのレアリティの★の数に応じて、ボーナスが加算される",
  "Event Period": "開催期間",
  "・Monthly": "・毎月",
  "User Grouping": "ユーザーのグルーピング",
  "・Grouped by Sleep Rank": "・スリープランク別にグルーピングする",
  "・Rewards are provided for each range band of rankings":
    "・報酬は、ランキングのレンジ帯毎に提供されます",
  "・Participants in higher Sleep Rank groups receive better rewards":
    "・上位のSLEEP RANKのグループの方が良い報酬がもらえます",
  "・Rewards include EGG, SPT, and items, and top-ranking participants also have the opportunity to earn GSPT (Governance Tokens)":
    "・報酬はEGGやSPT、アイテムなどに加え、ランキング上位者はGSPT（ガバナンストークン）の獲得も可能です",
  "Ways to Obtain": "入手方法",
  "・Hatching EGG": "・EGGの孵化",
  "・Purchase on the marketplace": "・マーケットプレイスでの購入",
  Rarity: "レアリティ",
  "・Free Distribution (Limited Quantity)": "・無料配布（数量限定）",
  "・Generation from SHEEPN": "・SHEEPNからの生成",
  "・Serial Code (*Scheduled)": "・シリアルコード（※予定）",
  "CONTEST Overview": "CONTEST概要",
  "Ranking Reward": "RANKING報酬",
  "Ongoing Roadmap": "進行中のロードマップ",
  "Completed Roadmap": "完了済のロードマップ",
  Terms: "利用規約",
  "Privacy Policy": "プライバシーポリシー",
  Disclaimer: "免責事項",
  description:
    "<p> SHEEPNはEGGから生まれるNFTです。</p><p> SHEEPNはそれぞれがユニークな外見や「資質」を持ち、<br/> まったく同じSHEEPNは2つと存在しません。</p><p> レアリティの高いSHEEPNを所有し、コンテストなどで活躍させることでユーザーはSPTやGSPTを稼ぐことができます。<br/> SHEEPNはSPTやGSPTを使用してトレーニングさせることで強くすることができるほか、<br/> 1度に限りEGGを生み出すことができます。また、SHEEPNはマーケットプレイスで売買することが可能です。</p>",
  description_mobile:
    "<p> SHEEPNはEGGから生まれるNFTです。 </p> <p> SHEEPNはそれぞれがユニークな外見や「資質」を持ち、まったく同じSHEEPNは2つと存在しません。</p> <p> レアリティの高いSHEEPNを所有し、コンテストなどで活躍させることでユーザーはSPTやGSPTを稼ぐことができます。<br/> SHEEPNはSPTやGSPTを使用してトレーニングさせることで強くすることができるほか、1度に限りEGGを生み出すことができます。 また、SHEEPNはマーケットプレイスで売買することが可能です。</p>",
  GO_BACK: "戻る",
  BACK_TO_HOME: "HOMEへ戻る",
  VIEW_MORE: "もっと見る",
  bannerDescription1: "※在庫数については､NFT の絵柄の種類問わず､その合計数を表します｡",
  bannerDescription2: "※ガチャの結果に従い､SHEEPN NFT を獲得します｡絵柄の指定はできません｡",
  bannerDescription3: "※在庫の有無に問わず､運営の判断により､予告なくセールを終了する可能性があります｡あらかじめご了承ください｡",
  bannerDescription4: "※運営による在庫の有無のお問い合わせへの回答はいたしかねます｡あらかじめご了承ください｡",
  titleSales: "｢{{name}} x SLEEPASS｣コラボNFT 販売！",
  saleDescription1:
    "SLEEPASSプロジェクトでは、NFTセール第一回目を記念して､{{name}}さんとのコラボレーションによる特別なNFTを販売します｡これらのNFT作品は、今回限りの限定販売であり、貴重なコレクションアイテムとなることは間違いありません。",
  saleDescription2:
    "今回のNFTセールでは、ガチャの回数券を購入して、ガチャを回して頂くことで､ランダムにNFTを入手できます。各NFTには、独自のデザインや特性があり、コレクション価値が高いものもございます。ぜひ高レアなNFTの獲得を目指し､何度もトライしてみてください｡",
  titleContents: "今回のセール内容はこちら！",
  buttonContent1: "ガチャの詳細",
  buttonContent2: "価格",
  buttonContent3: "ガチャを回す >",
  titleGatcha: "ガチャの中身",
  btnGatcha: "NFT ガチャ回数券",
  timeGatcha: "残り   {{time}}   回",
  loginWeb3: "SLEEPASSアカウントでログインする",
  logoutWeb3: "Logout",
  titleModal: "Web3Auth Login",
  loginGoogle: "Googleでログイン",
  loginApple: "Sign in with Apple",
  gatchaPageTitle: "NFT ガチャ回数券購入",
  gatcha01Title: "１連ガチャ",
  gatcha01: "1連ガチャのビジュアル",
  gatchaBtn: "購入する",
  gatcha10Title: "１０連ガチャ",
  gatcha10: "10連ガチャのビジュアル",
  gatcha01Des: "Description",
  gatcha10Des: "Description",
  modalGatchaTitle: "購入が完了しました",
  modalGatchaDes: "Description",
  rollGatcha: "ガチャを回す",
  gatchaCompletedTitle: "SLEEPASS NFT ガチャへようこそ！",
  buttonCompleted: "回数券を使ってガチャを回す",
  detailTitle: "SHEEPN を獲得！",
  reRoll: "回数券を使ってガチャをもう一度回す",
  buyTicket: "回数券を購入する",
  topPage: "TOP へ戻る",
  detailDes:
    "DescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescription\nDescriptionDescription",
  rarity: "Rarity",
  level: "Level",
  type: "Type",
  level2: "Lv.",
  layEgg: "Lay Egg's Reality Odds",
  bodyPart: "Body parts",
  bodyConscious: "Body Conscious",
  muscleStrength: "Muscle Strength",
  agility: "Agility",
  intelligence: "Intelligence",
  luck: "Luck",
  titleTickets: "今回のセール内容はこちら！",
  WellCome: {
    title: "SLEEPASSプロジェクトの NFTセールページへようこそ！",
    description:
      "ここでは、ガチャの回数券を購入して貴重なNFTが当たるガチャに挑戦することが可能です！是非ご参加ください！",
  },
  termsAndConditions: "登録することで、利用規約とプライバシーポリシーに同意するものとします。",
  close: "閉じる",
  titleTranferModal: "トークン転送先アドレス",
  warningTransfer1: "【重要】以下のアドレスにトークンの転送をお願いいたします｡\nトークンの転送後､以下に記載のメールアドレス宛に､転送量､転送元アドレスがわかる内容を記載の上､メールの送付をお願いいたします｡",
  warningTransfer2: "転送先のアドレス上で､お客様の転送したトークンの確認が取れ次第､お客様のアカウントにガチャチケットを付与し､登録されたメールアドレス宛にメールをお送りします｡",
  descriptionTransferModal: "※これらの情報は､転送元のお客様の特定を行うために必ず必要です｡必ず､間違いのないように記載をよろしくお願いいたします｡\n※｢七瀬アリス｣コラボ､｢パピコ｣コラボ 両方のガチャチケット分をまとめて転送されたい方は､メールにその旨をご記載いただきますようお願いいたします｡"
};
