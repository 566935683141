import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { gatchaGroupsThunk, gatchaRewardThunk } from "./thunk";
import { IGatchaEvents, IReward } from "./../../../types";
import { IEventGroup } from "@dto/index";
import Storage from "@utils/storage";
interface GatChaState {
  gatchaGroups: IGatchaEvents | null;
  loading: boolean;
  error?: string | null | any;
  gatchaReward: IReward | null;
  groupIdSelected: number | null;
}

const initialState: GatChaState = {
  gatchaGroups: null,
  loading: false,
  error: null,
  gatchaReward: null,
  groupIdSelected: (Storage("session").get("EVENT_GROUP") as IEventGroup)?.id,
};

export const gatchaSlice = createSlice({
  name: "gatcha",
  initialState,
  reducers: {
    setGroupId: (state, action) => {
      state.groupIdSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(gatchaGroupsThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      gatchaGroupsThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.gatchaGroups = action.payload;
      },
    );
    builder.addCase(gatchaGroupsThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(gatchaRewardThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      gatchaRewardThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.gatchaReward = action.payload;
      },
    );
    builder.addCase(gatchaRewardThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { setGroupId } = gatchaSlice.actions;
export const selectGatcha = (state: RootState) => state.gatcha;
export default gatchaSlice.reducer;
