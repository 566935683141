import { fetchLogin, fetchUserProfile } from "@api/auth";
import { AsyncThunkPayloadCreator, createAsyncThunk } from "@reduxjs/toolkit";

const login: AsyncThunkPayloadCreator<void, any> = async ({
  public_key,
  token,
  walletAddress,
}: {
  public_key: string;
  token: string;
  walletAddress: string;
}) => {
  try {
    const result: any = await fetchLogin({ public_key, token, walletAddress });
    return result.data;
  } catch (err) {
    throw err;
  }
};

const getUserProfile: AsyncThunkPayloadCreator<void, any> = async () => {
  try {
    const result: any = await fetchUserProfile();
   
    return result;
  } catch (err) {
    throw err;
  }
};

export const loginThunk = createAsyncThunk("auth/login", login);
export const getUserProfileThunk = createAsyncThunk(
  "auth/getUserProfile",
  getUserProfile
);
