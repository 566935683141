import { AsyncThunkPayloadCreator, createAsyncThunk } from "@reduxjs/toolkit";
import { listGatchaGroups, userGatchaReward } from "@api/gatcha";

const gatchaGroups: AsyncThunkPayloadCreator<void, any> = async () => {
  try {
    const res: any = await listGatchaGroups();
    return res;
  } catch (error) {
    throw error;
  }
};

const gatchaReward: AsyncThunkPayloadCreator<void, any> = async (payloads: {
  gacha_event_group_id: number;
}) => {
  try {
    const res: any = await userGatchaReward(payloads);
    return res;
  } catch (error) {
    throw error;
  }
};

export const gatchaGroupsThunk = createAsyncThunk(
  "gatcha/groups",
  gatchaGroups
);
export const gatchaRewardThunk = createAsyncThunk(
  "gatcha/reward",
  gatchaReward
);
