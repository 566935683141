export const STORAGE_KEY = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  AUTH_TOKEN: "auth_token",
  W3A_TOKEN: "web3Token",
  LANGUAGE: "language",
};

export enum SHEEP_PARAMS {
  BodyConscious = "Body Conscious",
  MuscleStrength = "Muscle Strength",
  Agility = "Agility",
  Intelligence = "Intelligence",
}
