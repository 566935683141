import GatchaReducer from "./slice/gatcha/gatcha";
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import AuthReducer from "./slice/auth";
import TicketReducer from "./slice/ticket";

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    ticket: TicketReducer,
    gatcha: GatchaReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
