export const EN: {
  [key: string]: any;
} = {
  "No data": "No data",
  "SLEEPASS is a sleep management app equipped with game-fi features":
    "SLEEPASS is a sleep management app equipped with game-fi feature",
  "By nurturing and hatching EGG (NFT) in the gaming world, you can earn SHEEPN (NFT).":
    "By nurturing and hatching EGG (NFT) in the gaming world, you can earn SHEEPN (NFT).",
  "In the real world, obtaining high-quality sleep allows you to accquire even rarer NFTs.":
    "In the real world, obtaining high-quality sleep allows you to accquire even rarer NFTs.",
  "Furthermore, by using these NFTs to participate in contests and achieve a high ranking, you can also earn Governance Tokens (GSPT).":
    "Furthermore, by using these NFTs to participate in contests and achieve a high ranking, you can also earn Governance Tokens (GSPT).",
  "Enjoy a new experience of acquiring NFTs while improving your sleep in a gamified manner.":
    "Enjoy a new experience of acquiring NFTs while improving your sleep in a gamified manner.",
  "Please download the SLEEPASS app from the App Store (iOS) or Play Store (Android).":
    "Please download the SLEEPASS app from the App Store (iOS) or Play Store (Android).",
  "※ Comming soon": "※ Comming soon",
  "Launch SLEEPASS and set an EGG in the SLOT. When accessing the app for the first time on each device, you can obtain an EGG (basic version for free). Afterward, feel free to set the EGG you want to hatch in the SLOT.":
    "Launch SLEEPASS and set an EGG in the SLOT. When accessing the app for the first time on each device, you can obtain an EGG (basic version for free). Afterward, feel free to set the EGG you want to hatch in the SLOT.",
  "Set the alarm Set an alarm for you planned wake-up time. Set it for a time that allows you to get enough sleep.":
    "Set the alarm Set an alarm for you planned wake-up time. Set it for a time that allows you to get enough sleep.",
  "We plan to organize a CONTEST where you can assemble a deck with SHEEPN and compete in rankings against other users!":
    "We plan to organize a CONTEST where you can assemble a deck with SHEEPN and compete in rankings against other users!",
  "Start collecting SHEEPN from now to boost your SLEEP RANK in preparation for the CONTEST!":
    "Start collecting SHEEPN from now to boost your SLEEP RANK in preparation for the CONTEST!",
  "We detect falling asleep/waking up using smartphone sensors and score sleep results based on our unique sleep evaluation logic.":
    "We detect falling asleep/waking up using smartphone sensors and score sleep results based on our unique sleep evaluation logic.",
  "In addition to Level 1, we import sleep data from health-related devices equipped with sleep detection features, such as smartwatches like the Apple Watch, and other healthcare-related devices, via HealthKit/Google Fit to the app, providing more accurate sleep data input.":
    "In addition to Level 1, we import sleep data from health-related devices equipped with sleep detection features, such as smartwatches like the Apple Watch, and other healthcare-related devices, via HealthKit/Google Fit to the app, providing more accurate sleep data input.",
  "Feedback to EGG/SHEEPN": "Feedback to EGG/SHEEPN",
  Contest: "Contest",
  "・Assemble a deck with 3 SHEEPN and compete based on the total Contest Points":
    "・Assemble a deck with 3 SHEEPN and compete based on the total Contest Points",
  "Determination of Contest Points": "Determination of Contest Points",
  "・Total parameter values serve as the base":
    "・Total parameter values serve as the base",
  "・For each contest, there are specific theme traits and parameters. If there's a match, a bonus is added (Theme Bonus, Parameter Bonus)":
    "・For each contest, there are specific theme traits and parameters. If there's a match, a bonus is added (Theme Bonus, Parameter Bonus)",
  "Recommended Trait Bonus": "Recommended Trait Bonus",
  "・When the trait type matches the contest's theme, n% of the entire SHEEPN parameter values are added as a bonus":
    "・When the trait type matches the contest's theme, n% of the entire SHEEPN parameter values are added as a bonus",
  "Recommended Parameter Bonus": "Recommended Parameter Bonus",
  "・For the parameters set as the theme of the contest, m% of the corresponding SHEEPN parameter values are added as a bonus":
    "・For the parameters set as the theme of the contest, m% of the corresponding SHEEPN parameter values are added as a bonus",
  "Support Bonus": "Support Bonus",
  "Depending on the number of ★ rarities of SHEEPN with trait types that match the contest's theme and are not set in the deck, a bonus is added":
    "Depending on the number of ★ rarities of SHEEPN with trait types that match the contest's theme and are not set in the deck, a bonus is added",
  "Event Period": "Event Period",
  "・Monthly": "・Monthly",
  "User Grouping": "User Grouping",
  "・Grouped by Sleep Rank": "・Grouped by Sleep Rank",
  "・Rewards are provided for each range band of rankings":
    "・Rewards are provided for each range band of rankings",
  "・Participants in higher Sleep Rank groups receive better rewards":
    "・Participants in higher Sleep Rank groups receive better rewards",
  "・Rewards include EGG, SPT, and items, and top-ranking participants also have the opportunity to earn GSPT (Governance Tokens)":
    "・Rewards include EGG, SPT, and items, and top-ranking participants also have the opportunity to earn GSPT (Governance Tokens)",
  "Ways to Obtain": "Ways to Obtain",
  "・Hatching EGG": "・Hatching EGG",
  "・Purchase on the marketplace": "・Purchase on the marketplace",
  Rarity: "Rarity",
  "・Free Distribution (Limited Quantity)":
    "・Free Distribution (Limited Quantity)",
  "・Generation from SHEEPN": "・Generation from SHEEPN",
  "・Serial Code (*Scheduled)": "・Serial Code (*Scheduled)",
  "CONTEST Overview": "CONTEST Overview",
  "Ranking Reward": "Ranking Reward",
  "Ongoing Roadmap": "Ongoing Roadmap",
  "Completed Roadmap": "Completed Roadmap",
  Terms: "Terms",
  "Privacy Policy": "Privacy Policy",
  Disclaimer: "Disclaimer",
  description:
    '<p>SHEEPN is an NFT born from EGG. </p> <p>Each SHEEPN possesses a unique appearance and "traits" and there are no two identical SHEEPNs. </p> <p>Users can earn SPT and GSPT by owning rare SHEEPNs and showcasing them in contests. </br> SHEEPNs can be strengthened by training with SPT and GSPT, and they can also produce EGG, but only once. </p> <p>Additionally, SHEEPNs can be bought and sold on the marketplace.</p>',
  description_mobile:
    '<p>SHEEPN is an NFT born from EGG. </p> <p>Each SHEEPN possesses a unique appearance and "traits" and there are no two identical SHEEPNs. </p> <p>Users can earn SPT and GSPT by owning rare SHEEPNs and showcasing them in contests. </br> SHEEPNs can be strengthened by training with SPT and GSPT, and they can also produce EGG, but only once. </p> <p>Additionally, SHEEPNs can be bought and sold on the marketplace.</p>',
  GO_BACK: "go back",
  BACK_TO_HOME: "Back to home",
  VIEW_MORE: "view more",
  bannerDescription1: "※在庫数については､NFT の絵柄の種類問わず､その合計数を表します｡",
  bannerDescription2: "※ガチャの結果に従い､SHEEPN NFT を獲得します｡絵柄の指定はできません｡",
  bannerDescription3: "※在庫の有無に問わず､運営の判断により､予告なくセールを終了する可能性があります｡あらかじめご了承ください｡",
  bannerDescription4: "※運営による在庫の有無のお問い合わせへの回答はいたしかねます｡あらかじめご了承ください｡",
  titleSales: "｢{{name}} x SLEEPASS｣コラボNFT 販売！",
  saleDescription1:
    "SLEEPASSプロジェクトでは、NFTセール第一回目を記念して､{{name}}さんとのコラボレーションによる特別なNFTを販売します｡これらのNFT作品は、今回限りの限定販売であり、貴重なコレクションアイテムとなることは間違いありません。",
  saleDescription2:
    "今回のNFTセールでは、ガチャの回数券を購入して、ガチャを回して頂くことで､ランダムにNFTを入手できます。各NFTには、独自のデザインや特性があり、コレクション価値が高いものもございます。ぜひ高レアなNFTの獲得を目指し､何度もトライしてみてください｡",
  titleContents: "今回のセール内容はこちら！",
  buttonContent1: "ガチャの詳細",
  buttonContent2: "価格",
  buttonContent3: "ガチャを回す >",
  titleGatcha: "ガチャの中身",
  btnGatcha: "NFT ガチャ回数券",
  timeGatcha: "残り   {{time}}   回",
  loginWeb3: "SLEEPASSアカウントでログインする",
  logoutWeb3: "Logout",
  titleModal: "Web3Auth Login",
  loginGoogle: "Googleでログイン",
  loginApple: "Sign in with Apple",
  gatchaPageTitle: "NFT ガチャ回数券購入",
  gatcha01Title: "１連ガチャ",
  gatcha01: "1連ガチャのビジュアル",
  gatchaBtn: "購入する",
  gatcha10Title: "１０連ガチャ",
  gatcha10: "10連ガチャのビジュアル",
  gatcha01Des: "Description",
  gatcha10Des: "Description",
  modalGatchaTitle: "購入が完了しました",
  modalGatchaDes: "Description",
  rollGatcha: "ガチャを回す",
  gatchaCompletedTitle: "SLEEPASS NFT ガチャへようこそ！",
  buttonCompleted: "回数券を使ってガチャを回す",
  detailTitle: "SHEEPN を獲得！",
  reRoll: "回数券を使ってガチャをもう一度回す",
  buyTicket: "回数券を購入する",
  topPage: "TOP へ戻る",
  detailDes:
    "DescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescription\nDescriptionDescription",
  rarity: "Rarity",
  level: "Level",
  type: "Type",
  level2: "Lv.",
  layEgg: "Lay Egg's Reality Odds",
  bodyPart: "Body parts",
  bodyConscious: "Body Conscious",
  muscleStrength: "Muscle Strength",
  agility: "Agility",
  intelligence: "Intelligence",
  luck: "Luck",
  titleTickets: "今回のセール内容はこちら！",
  WellCome: {
    title: "SLEEPASSプロジェクトの NFTセールページへようこそ！",
    description:
      "ここでは、ガチャの回数券を購入して貴重なNFTが当たるガチャに挑戦することが可能です！是非ご参加ください！",
  },
  termsAndConditions: "登録することで、利用規約とプライバシーポリシーに同意するものとします。",
  close: "閉じる",
  descriptionTransferModal: "【重要】以下のアドレスにトークンの転送をお願いいたします｡\nトークンの転送後､以下に記載のメールアドレス宛に､転送量､転送元アドレスがわかる内容を記載の上､メールの送付をお願いいたします｡\n\n※これらの情報は､転送元のお客様の特定を行うために必ず必要です｡必ず､間違いのないように記載をよろしくお願いいたします｡\n※｢七瀬アリス｣コラボ､｢パピコ｣コラボ 両方のガチャチケット分をまとめて転送されたい方は､メールにその旨をご記載いただきますようお願いいたします｡"
};
