import { CHAIN_NAMESPACES, ChainNamespaceType } from "@web3auth/base";

export interface ICustomConfigs {
  chainNamespace: ChainNamespaceType;
  chainId: string;
  rpcTarget: string;
  displayName: string;
  blockExplorer?: string;
  ticker: string;
  tickerName: string;
}

export const chain: {
  [key: string]: ICustomConfigs;
} = {
  Polygon: {
    // chainNamespace: process.env.REACT_APP_WEB3_CHAIN_NAMES as ChainNamespaceType,
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: process.env.REACT_APP_WEB3_CHAIN_ID || '',
    rpcTarget: process.env.REACT_APP_WEB3_RPC || 'https://rpc.ankr.com/polygon',
    displayName: process.env.REACT_APP_WEB3_DISPLAY_NAME || "Polygon Mainnet",
    blockExplorer: process.env.REACT_APP_WEB3_BLOCK_URL,
    ticker: process.env.REACT_APP_WEB3_TICKER || '',
    tickerName: process.env.REACT_APP_WEB3_TICKER_NAME || '',
  },
};
