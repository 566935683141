import http from "@libs/axios";

const listGatchaGroups = async () => {
  const res: any = await http.getInstance("main", true).get("/gacha_groups");

  if (res.errors) {
    throw new Error(res.message);
  }
  return res.data;
};

const userGatchaReward = async (payloads: { gacha_event_group_id: number }) => {
  const res: any = await http
    .getInstance("main", true)
    .post("/user_gacha_reward", payloads);

  if (res.errors) {
    throw new Error(res.message);
  }
  return res.data;
};

export { listGatchaGroups, userGatchaReward };
