import http from "@libs/axios";

const fetchLogin = async ({
  public_key,
  token,
  walletAddress,
}: {
  public_key: string;
  token: string;
  walletAddress: string;
}) => {
  const result = await http.getInstance("main", true).post(
    "/auth/login",
    { public_key },
    {
      headers: {
        "x-id-token": token,
        "x-wallet-address": walletAddress,
      },
    },
  );
  if (result.data?.api_key) {
    return result;
  }
  throw new Error(result.data.message);
};

const fetchUserProfile = async () => {
  const result: any = await http
    .getInstance("main", true)
    .get("/gacha/user/info");

  if (result.errors) {
    throw new Error(result.message);
  }
  return result;
};

export { fetchLogin, fetchUserProfile };
